import React, {useContext, useState} from 'react';
import {
    ActionIcon, Blockquote, Box,
    Button,
    Card,
    Center,
    Container,
    Flex, getGradient, getThemeColor, Highlight, Image,
    List, Mark, Paper,
    rem, Space,
    Stack,
    Stepper,
    Text,
    ThemeIcon,
    Title, useMantineTheme
} from "@mantine/core";
import {Link as RouterLink} from 'react-router-dom';
import Footer from "../Footer";
import {ParallaxProvider} from "react-scroll-parallax";
import SmallYarn from "../Shapes/smallYarn";
import MediumYarn from "../Shapes/mediumYarn";
import LargeYarn from "../Shapes/largeYarn";
import crochetLogo from "../../Images/crochetLogo.svg";

import Feedback from "./Feedback";
import {
    IconAbacus,
    IconCaretDownFilled,
    IconCaretUpFilled,
    IconCircleCheck,
    IconEdit,
    IconFileExport,
    IconNeedleThread,
    IconSquaresFilled,
    IconTextWrap,
    IconUpload
} from "@tabler/icons-react";
import FeatureFrames from "./FeatureFrames";
import {UserContext} from "../../Context/User";
import {whiteFilter} from "../PatternBuilder/PatternBuilderManager";
import {useMediaQuery} from "@mantine/hooks";


const HomeShell = () => {
    const [alphaPatternStep, setAlphaPatternStep] = useState(0);
    const [symbolPatternStep, setSymbolPatternStep] = useState(0);
    const [rowCounterStep, setRowCounterStep] = useState(0);

    const theme = useMantineTheme()
    const { user } = useContext(UserContext);
    const isMobile = useMediaQuery('(max-width: 768px)');


    const nextAlphaPatternStep = () => setAlphaPatternStep((current) => (current < 4 ? current + 1 : current));
    const prevAlphaPatternStep = () => setAlphaPatternStep((current) => (current > 0 ? current - 1 : current));
    const nextSymbolPatternStep = () => setSymbolPatternStep((current) => (current < 3 ? current + 1 : current));
    const prevSymbolPatternStep = () => setSymbolPatternStep((current) => (current > 0 ? current - 1 : current));
    const nextRowCounterStep = () => setRowCounterStep((current) => (current < 4 ? current + 1 : current));
    const prevRowCounterStep = () => setRowCounterStep((current) => (current > 0 ? current - 1 : current));

    return (
        <>
            <Space h="10vh" />
            <ParallaxProvider>
                <SmallYarn/>
                <MediumYarn/>
                <LargeYarn/>
            </ParallaxProvider>
                        <Flex
                            mih={"85vh"}
                            w="100%"
                            direction={{ sm: "row", base: "column" }}
                            justify="flex-start"
                            align="flex-start"
                        >
                    <Container p={0} m={0} mih={"100%"} style={{backgroundColor: getThemeColor('violet', theme), flex: 1}}>
      <Stack spacing="xl" c="white" p={"md"}>
          {/*<Paper style={{        backgroundImage:*/}
          {/*'linear-gradient(45deg, var(--mantine-color-violet-4), var(--mantine-color-violet-9))'}}>*/}
        <Title align="center" order={1} size="h1" p={"xl"}>
          Crochet Crafters
        </Title>
              {/*</Paper>*/}
  {/*                                <Image*/}
  {/*                      src={crochetLogo}*/}
  {/*style={{*/}
  {/*  maxHeight: '20vh',*/}
  {/*  maxWidth: '100%',*/}
  {/*  height: 'auto',*/}
  {/*  width: 'auto',*/}
  {/*        objectFit: 'contain',*/}
  {/*    filter: whiteFilter*/}

  {/*}}*/}
  {/*                  ></Image>*/}

        <Text align="center" size="lg" p={"xl"} fw={500}>
          Start your next crochet masterpiece today!
        </Text>
          {user ? null : <>
                                            <Button variant="white" component={RouterLink} to="/sign-in">
                Sign In
            </Button>
            <Button variant="white" component={RouterLink} to="/sign-up">
                Sign Up
            </Button></>}
      </Stack>
                    </Container>
                            <Container style={{flex: 1}} h={"100%"}>
                                <Blockquote h={"100%"} color={"violet"}>
                                    <Paper shadow="xl">
                                        <Blockquote>
                                                                                <Text fw={500} size={"lg"}>
    <Highlight
      ta="center"
      highlight={['passionate crocheters', 'alpha patterns', 'symbol designs', 'keeping track of rows']}
      highlightStyles={{
        backgroundImage:
          'linear-gradient(45deg, var(--mantine-color-violet-4), var(--mantine-color-violet-9))',
        fontWeight: 700,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
    >
Unlock your creativity with our tools designed specifically for passionate crocheters. Whether you're crafting personalized alpha patterns, intricate symbol designs, or just keeping track of rows, we've got you covered.
    </Highlight>
    </Text>

                                                                                </Blockquote>
                                        </Paper>
                                                                    <List
                                    p={"xl"}
                                    spacing="md"
                                    size="md"
                                    center
                                    icon={
                                        <ThemeIcon color="violet" size={24} radius="xl">
                                            <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                        </ThemeIcon>
                                    }
                                >
<List.Item>
  <Paper shadow="xl" p="xs">
      <Text inline fw={1000} variant="gradient">
        Alpha Pattern Generator
      </Text>
      <Text fw={500} inline>Create and design grid patterns from uploaded images</Text>
  </Paper>
</List.Item>
                                                                        <List.Item>
  <Paper shadow="xl" p="xs">
      <Text inline fw={1000} variant="gradient">
        Symbol Pattern Generator
      </Text>
      <Text fw={500} inline>Draw from our library of symbols to design your own patterns</Text>
  </Paper>
</List.Item>
                                                                        <List.Item>
  <Paper shadow="xl" p="xs">
      <Text inline fw={1000} variant="gradient">
        Row Counter
      </Text>
      <Text fw={500} inline>Keep track of your progress on various projects</Text>
  </Paper>
</List.Item>
                                </List>
                                    </Blockquote>
                    </Container>
                        </Flex>
                <Stack
      gap={"100px"}
      mt={"100px"}
      style={{
          marginTop: '100px',
          margin: 'auto',
          width: isMobile ? "100%" : "70%"
                }}
    >

            <Card
                shadow="sm"
                radius="md"
                withBorder
                w="100%"
            >
                <Card.Section
                    >
                        <Center>
                            <Title order={2} size="h2" style={{zIndex: 2}} color="white" p="md">
                                Alpha Pattern
                            </Title>
                        </Center>
                </Card.Section>
                <Flex
                    direction={{ xs: "row", base: "column" }}
                    justify="flex-start"
                    align="flex-start"
                >
                    <Container>

                        <Stepper m="md" active={alphaPatternStep} onStepClick={setAlphaPatternStep}
                                 orientation="vertical" breakpoint="sm">
                            <Stepper.Step icon={<IconUpload/>} label="Upload">
                            </Stepper.Step>
                            <Stepper.Step icon={<IconEdit/>} label="Edit">
                            </Stepper.Step>
                            <Stepper.Step icon={<IconAbacus/>} label="Count">
                            </Stepper.Step>
                            <Stepper.Step icon={<IconTextWrap/>} label="Export">
                            </Stepper.Step>
                            <Stepper.Completed>
                            </Stepper.Completed>
                        </Stepper>
                                                <ActionIcon.Group>
                          <ActionIcon disabled={alphaPatternStep === 0} onClick={prevAlphaPatternStep} variant="default" size="lg" aria-label="Gallery">
                            <IconCaretUpFilled style={{ width: rem(20) }} stroke={1.5} />
                          </ActionIcon>

                          <ActionIcon disabled={alphaPatternStep === 4} onClick={nextAlphaPatternStep} variant="default" size="lg" aria-label="Settings">
                            <IconCaretDownFilled style={{ width: rem(20) }} stroke={1.5} />
                          </ActionIcon>
                        </ActionIcon.Group>
                    </Container>
                    <Container style={{flex: 1}}>
                        {alphaPatternStep === 0 &&
                            <Stack>
                                <FeatureFrames pathKey={"alphaPatternUpload"} alt={"Alpha Pattern Upload"}/>
                                <List
                                    p={"xl"}
                                    spacing="md"
                                    size="md"
                                    center
                                    icon={
                                        <ThemeIcon color="violet" size={24} radius="xl">
                                            <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                        </ThemeIcon>
                                    }
                                >
                                    <List.Item>Upload any image of your choice</List.Item>
                                    <List.Item>Set its size</List.Item>
                                    <List.Item>Change its colours</List.Item>
                                    <List.Item>Give it a name</List.Item>
                                    <List.Item>Generate your starting pattern</List.Item>
                                </List>
                            </Stack>
                        }
                        {alphaPatternStep === 1 &&
                            <Stack>
                                <FeatureFrames pathKey={"alphaPatternEdit"} alt={"Alpha Pattern Edit"}/>
                                <List
                                    p={"xl"}
                                    spacing="md"
                                    size="md"
                                    center
                                    icon={
                                        <ThemeIcon color="violet" size={24} radius="xl">
                                            <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                        </ThemeIcon>
                                    }
                                >
                                    <List.Item>Paint individual cells with the brush tool</List.Item>
                                    <List.Item>Paint large areas with the fill tool</List.Item>
                                    <List.Item>Draw lines to measure and guide with the line tool</List.Item>
                                    <List.Item>Change dimensions by adding/removing rows from any side of your
                                        creation</List.Item>
                                </List>
                            </Stack>
                        }
                        {alphaPatternStep === 2 &&
                            <Stack>
                                <FeatureFrames pathKey={"alphaPatternCount"} alt={"Alpha Pattern Count"}/>
                                <List
                                    p={"xl"}
                                    spacing="md"
                                    size="md"
                                    center
                                    icon={
                                        <ThemeIcon color="violet" size={24} radius="xl">
                                            <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                        </ThemeIcon>
                                    }
                                >
                                    <List.Item>Choose the direction you want to navigate through each row</List.Item>
                                    <List.Item>View how many stitches are required in each colour for a given
                                        row</List.Item>
                                </List>
                            </Stack>
                        }
                        {alphaPatternStep === 3 &&
                            <Stack>
                                <FeatureFrames pathKey={"alphaPatternExport"} alt={"Alpha Pattern Export"}/>
                                <List
                                    p={"xl"}
                                    spacing="md"
                                    size="md"
                                    center
                                    icon={
                                        <ThemeIcon color="violet" size={24} radius="xl">
                                            <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                        </ThemeIcon>
                                    }
                                >
                                    <List.Item>View how many stitches are required for each colour</List.Item>
                                    <List.Item>Change the generated colour names to suit your needs</List.Item>
                                    <List.Item>Generate the pattern in text format and copy it wherever you'd
                                        like</List.Item> </List>
                            </Stack>
                        }
                        {alphaPatternStep === 4 &&
                            <Stack>
                                <Button h={"20vh"} fullWidth component={RouterLink} to="/alphapattern">
                                    Try it out yourself!
                                </Button>
                            </Stack>
                        }
                    </Container>
                </Flex>
            </Card>


            <Card
                shadow="sm"
                radius="md"
                withBorder
                w="100%"
            >
                <Card.Section>
                        <Center>
                            <Title order={2} size="h2" style={{zIndex: 2}} color="white" p="md">
                                Symbol Pattern
                            </Title>
                        </Center>
                </Card.Section>
                <Flex
                    direction={{ xs: "row", base: "column" }}
                    justify="flex-start"
                    align="flex-start"
                >
                    <Container>

                        <Stepper m="md" active={symbolPatternStep} onStepClick={setSymbolPatternStep}
                                 orientation="vertical" breakpoint="sm">
                            <Stepper.Step icon={<IconNeedleThread/>} label="Design">
                            </Stepper.Step>
                            <Stepper.Step icon={<IconSquaresFilled/>} label="Layer">
                            </Stepper.Step>
                            <Stepper.Step icon={<IconFileExport/>} label="Export">
                            </Stepper.Step>
                            <Stepper.Completed>
                            </Stepper.Completed>
                        </Stepper>
                        <ActionIcon.Group>
                          <ActionIcon disabled={symbolPatternStep === 0} onClick={prevSymbolPatternStep} variant="default" size="lg" aria-label="Gallery">
                            <IconCaretUpFilled style={{ width: rem(20) }} stroke={1.5} />
                          </ActionIcon>

                          <ActionIcon disabled={symbolPatternStep === 3} onClick={nextSymbolPatternStep} variant="default" size="lg" aria-label="Settings">
                            <IconCaretDownFilled style={{ width: rem(20) }} stroke={1.5} />
                          </ActionIcon>
                        </ActionIcon.Group>
                    </Container>
                    <Container style={{flex: 1}}>
                        {symbolPatternStep === 0 &&
                            <Stack>
                                <FeatureFrames pathKey={"symbolPatternDesign"} alt={"Symbol Pattern Design"}/>
                                <List
                                    p={"xl"}
                                    spacing="md"
                                    size="md"
                                    center
                                    icon={
                                        <ThemeIcon color="violet" size={24} radius="xl">
                                            <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                        </ThemeIcon>
                                    }
                                >
                                    <List.Item>Add symbols from the list</List.Item>
                                    <List.Item>Define their size</List.Item>
                                    <List.Item>Select the symbols to move, rotate, and resize</List.Item>
                                </List>
                            </Stack>
                        }
                        {symbolPatternStep === 1 &&
                            <Stack>
                                <FeatureFrames pathKey={"symbolPatternLayer"} alt={"Symbol Pattern Layer"}/>
                                <List
                                    p={"xl"}
                                    spacing="md"
                                    size="md"
                                    center
                                    icon={
                                        <ThemeIcon color="violet" size={24} radius="xl">
                                            <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                        </ThemeIcon>
                                    }
                                >
                                    <List.Item>Divide your symbols into different layers</List.Item>
                                    <List.Item>Hide/delete each layer</List.Item>
                                </List>
                            </Stack>
                        }
                        {symbolPatternStep === 2 &&
                            <Stack>
                                <FeatureFrames pathKey={"symbolPatternExport"} alt={"Symbol Pattern Export"}/>
                                <List
                                    p={"xl"}
                                    spacing="md"
                                    size="md"
                                    center
                                    icon={
                                        <ThemeIcon color="violet" size={24} radius="xl">
                                            <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                        </ThemeIcon>
                                    }
                                >
                                    <List.Item>Export your creation into an image and save it wherever you would
                                        like</List.Item>
                                </List>
                            </Stack>
                        }
                        {symbolPatternStep === 3 &&
                            <Stack>

                                <Button h={"20vh"} fullWidth component={RouterLink} to="/symbolpattern">
                                    Try it out yourself!
                                </Button>
                            </Stack>
                        }
                    </Container>
                </Flex>
            </Card>

            <Card
                shadow="sm"
                radius="md"
                withBorder
                w="100%"
            >
                <Card.Section>
                        <Center>
                            <Title order={2} size="h2" style={{zIndex: 2}} color="white" p="md">
                                Row Counter
                            </Title>
                        </Center>
                </Card.Section>
                <Flex
                    direction={{ xs: "row", base: "column" }}
                    justify="flex-start"
                    align="flex-start"
                >
                    <Container>

                        <Stepper m="md" active={rowCounterStep} onStepClick={setRowCounterStep}
                                 orientation="vertical" breakpoint="sm">
                            <Stepper.Step icon={<IconAbacus/>} label="Count">
                            </Stepper.Step>
                            <Stepper.Completed>
                            </Stepper.Completed>
                        </Stepper>
                        <ActionIcon.Group>
                          <ActionIcon disabled={rowCounterStep === 0} onClick={prevRowCounterStep} variant="default" size="lg" aria-label="Gallery">
                            <IconCaretUpFilled style={{ width: rem(20) }} stroke={1.5} />
                          </ActionIcon>

                          <ActionIcon disabled={rowCounterStep === 1} onClick={nextRowCounterStep} variant="default" size="lg" aria-label="Settings">
                            <IconCaretDownFilled style={{ width: rem(20) }} stroke={1.5} />
                          </ActionIcon>
                        </ActionIcon.Group>
                    </Container>
                    <Container style={{flex: 1}}>
                        {rowCounterStep === 0 &&
                            <Stack>
                                <FeatureFrames pathKey={"rowCounterCount"} alt={"Row Counter Count"}/>
                                <List
                                    p={"xl"}
                                    spacing="md"
                                    size="md"
                                    center
                                    icon={
                                        <ThemeIcon color="violet" size={24} radius="xl">
                                            <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                        </ThemeIcon>
                                    }
                                >
                                        <List.Item>Count your project stitches and rows</List.Item>
                                        <List.Item>Edit how many stitches the plus sign increments by</List.Item>
                                        <List.Item>View how many stitches each row has</List.Item>
                                        <List.Item>Delete previous rows</List.Item>
                                </List>
                            </Stack>
                        }
                        {rowCounterStep === 1 &&
                            <Stack>
                                <Button h={"20vh"} fullWidth component={RouterLink} to="/rowcounter">
                                    Try it out yourself!
                                </Button>
                            </Stack>
                        }
                    </Container>
                </Flex>
            </Card>
            <Feedback/>
                </Stack>
                        <Space h="10vh" />

            <Footer/>
        </>
    );
};

export default HomeShell;
