import React, {useRef, useState} from 'react';
import {ActionIcon, AppShell, Dialog, Grid, Tooltip, Alert as MantineAlert} from '@mantine/core';
import SymbolPatternCanvas from "./SymbolPatternCanvas";
import {v4 as uuidv4} from 'uuid';
import LayerCanvas from "./LayerCanvas";
import ToolSelect from "./ToolSelect";
import ExportCanvas from "./ExportCanvas";
import SymbolsCanvas from "./SymbolsCanvas";
import Scale from "./Scale";
import {IconDeviceFloppy, IconInfoCircle} from "@tabler/icons-react";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {useDisclosure, useMediaQuery} from "@mantine/hooks";
import {useLocation} from "react-router-dom";

const svgFiles = require.context('../../Images/crochetSymbols', false, /\.svg$/);
const svgFileNames = svgFiles.keys().map(key => key.substring(2));
// Create a reference list of files/names/images for each possible symbol
const symbols = svgFileNames.map(fileName => {
    const img = new Image();
    img.src = svgFiles(`./${fileName}`)
    return {
        file: svgFiles(`./${fileName}`),
        name: fileName.replace(/\.svg$/, '').replace(/_/g, ' '),
        image: img,
    }
});

const SymbolPatternEdit = () => {
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width: 768px)');

    const [viewport, setViewport] = useState({x: 0, y: 0, scale: 1});
    const [accordion, setAccordion] = useState("select")
    const [selectedSymbol, setSelectedSymbol] = useState(symbols[0]);
    const [canvasSymbols, setCanvasSymbols] = useState(location.state ? location.state.canvasSymbols : []);
    const [symbolSize, setSymbolSize] = useState(100)
    const [layers, setLayers] = useState(location.state ? location.state.layers : [{uuid: uuidv4(), hidden: false, name: ""}])
    const [selectedLayer, setSelectedLayer] = useState(layers[0])
    const [clickedSymbol, setClickedSymbol] = useState(null)
    const canvasRef = useRef(null);
    const bufferCanvasRef = useRef(null);
    const [name, setName] = useState(location.state ? location.state.name : "")
    const [uuid, setUuid] = useState(location.state ? location.state.uuid : null)
    const [severity, setSeverity] = React.useState('warning')
    const [opened, {toggle, close}] = useDisclosure(false);

    const handleSave = async (e) => {
        setSeverity('loading');
        if (!opened) toggle();

        const canvas = canvasRef.current;
        const dataURL = canvas.toDataURL("image/png");
        fetch(dataURL)
            .then(res => res.blob())
            .then((blob) => {
                const newUuid = uuidv4()
                const file = new File([blob], `${uuid ? uuid : newUuid}.jpg`, {type: 'image/jpeg'});
                const formData = new FormData();
                const data = {
                    canvasSymbols,
                    layers,
                }
                formData.append('uuid', uuid ? uuid : newUuid)
                formData.append('file', file);
                formData.append('name', name);
                formData.append('data', JSON.stringify(data))
                let method = 'POST'
                let url = `${process.env.REACT_APP_API_HOST}/symbol_pattern/`
                if (uuid) {
                    method = 'PUT'
                    url += `${uuid}/`
                }
                return makeAuthenticatedRequest(
                    url,
                    {method,
                    body: formData,
                    contentType: null}
                );
            })
            .then((response) => {
                if (response.ok) {
                    setSeverity('success');
                    if (!opened) toggle();
                } else {
                    setSeverity('warning');
                    if (!opened) toggle();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setSeverity('warning');
                if (!opened) toggle();
            });
    }

    return (
        <React.Fragment>
            <AppShell.Main>
                <Grid
                    align="center"
                    justify="center"
                >
                    <Grid.Col h="7vh" mah="7vh" mih="7vh" span={1}></Grid.Col>
                    <Grid.Col h="7vh" mah="7vh" mih="7vh" span={11}>
                        <div style={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
                            <Scale
                                setViewport={setViewport}
                            />
                        </div>
                    </Grid.Col>

                    <Grid.Col h="83vh" mah="83vh" mih="83vh" span={isMobile ? 2: 1}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                        <ToolSelect
                        accordion={accordion}
                        setAccordion={setAccordion}
                        />
                                    <Tooltip label="Save">
                                <ActionIcon
                                    variant="gradient"
                                    size="xl"
                                    aria-label="Save Icon"
                                    color="violet"
                                    mt={10}
                                    onClick={handleSave}
                                >
                                    <IconDeviceFloppy/>
                                </ActionIcon>
                            </Tooltip>
                                </div>
                    </Grid.Col>

                    <Grid.Col h="83vh" mah="83vh" mih="83vh" span={isMobile ? 10 : 11}>
                <SymbolPatternCanvas
                    viewport={viewport}
                    setViewport={setViewport}
                    symbols={symbols}
                    selectedSymbol={selectedSymbol}
                    canvasSymbols={canvasSymbols}
                    setCanvasSymbols={setCanvasSymbols}
                    symbolSize={symbolSize}
                    selectedLayer={selectedLayer}
                    layers={layers}
                    accordion={accordion}
                    clickedSymbol={clickedSymbol}
                    setClickedSymbol={setClickedSymbol}
                    canvasRef={canvasRef}
                    bufferCanvasRef={bufferCanvasRef}
                />
                    </Grid.Col>
                </Grid>
            </AppShell.Main>
            <AppShell.Aside style={{overflow: "scroll"}}>
                <LayerCanvas
                    layers={layers}
                    setLayers={setLayers}
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                    accordion={accordion}
                />
                <SymbolsCanvas
                    accordion={accordion}
                    symbolSize={symbolSize}
                    setSymbolSize={setSymbolSize}
                    selectedSymbol={selectedSymbol}
                    setSelectedSymbol={setSelectedSymbol}
                    symbols={symbols}
                />
                <ExportCanvas
                    accordion={accordion}
                    canvasRef={canvasRef}
                />

            </AppShell.Aside>
            <Dialog component={MantineAlert} variant="dark"
                    color={
                        severity === "success" ? "green" :
                            severity === "warning" ? "red" :
                                "violet"
                    }
                    title={
                        severity === "success" ? "Pattern saved!" :
                            severity === "warning" ? "An error occurred. Please try again" :
                                "saving..."
                    }
                    icon={<IconInfoCircle/>}
                    opened={opened} withCloseButton onClose={close} size="lg" radius="md">
            </Dialog>
        </React.Fragment>
    );
}

export default SymbolPatternEdit;
